import axios from "axios";
import { ItemsStore, FacetsStore } from "./Store";
import archieExports from "../archie-exports";
import SolrParams from '../SolrParams';

function formatDcDate(ts, addTime) {
  const date = new Date(ts);

  let d =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  if (addTime) {
    d += "T00:00:00Z";
  }
  return d;
}

function escapeQueryChars(query) {
  // eslint-disable-next-line
  return query.replace(/([\!\+\&\|\(\)\[\]\{\}\^\~\?\:\"\/])/g, ' ');

}

export function BuildSolrSearchQuery(params) {
  let solr_params = new SolrParams();
  params = Object.fromEntries([...params]);
  // search term
  let q = "";

  let userInput = params.searchTerm.trim();
  // search term modifier
  switch (params.searchTermModifier) {
    case "fuzzy":
      q = userInput + "~";
      break;
    case "exact":
      q = "'" + userInput + "'";
      break;
    case "any":
      q = userInput
        .split(" ")
        .join(" AND ");
      break;
    case "start":
      q = userInput + "*";
      break;
    case "end":
      q = "*" + userInput;
      break;
    default:
      console.log("Unknown term modifier: " + params.searchTermModifier);
  }

  let escaped = escapeQueryChars(q);
  // search in fields
  solr_params.q = params.searchInFields ? params.searchInFields + ":" + escaped : escaped;

  // dcCreator filter
  if (params.dcCreatorFilter) {
    solr_params.fq.push('dcCreator:"' + params.dcCreatorFilter + '"');
  }

  // dcAccessRights
  if (params.dcAccessRights) {
    solr_params.fq.push('dcAccessRights:"' + params.dcAccessRights + '"');
  }
  // collection filter
  if (params.dcCollectionFilter) {
    // https://stackoverflow.com/questions/10023133/solr-wildcard-query-with-whitespace
    let dcIsPartOf = params.dcCollectionFilter.split(" ").join("%20");
    solr_params.fq.push('dcIsPartOf:"' + dcIsPartOf + '"');
  }
  // date filters
  solr_params = addDateFilters(params, solr_params);
  // sort field
  switch (params.sortField) {
    case "dcTitle":
      solr_params.sort = "dcTitleString " + params.sortOrder + ",importTime asc,id asc";
      break;
    case "dcDate":
      solr_params.sort = "dcDate " + params.sortOrder + ",importTime asc,id asc";
      break;
    case "importTime":
      solr_params.sort = "importTime " + params.sortOrder + ",id asc";
      break;
    default:
      console.log("unknown sort field " + params.sortField);
  }
  return solr_params;
}

function addDateFilters(params, solr_params) {
  if (
    params.startDateFilter === undefined &&
    params.endDateFilter === undefined
  ) {
    return solr_params;
  }
  let startDate =
    params.startDateFilter === undefined
      ? +new Date()
      : parseInt(params.startDateFilter) * 1000;

  let endDate =
    params.endDateFilter === undefined
      ? +new Date()
      : parseInt(params.endDateFilter) * 1000;
  solr_params.fq.push("dcDate:[" + formatDcDate(startDate, true) + " TO " + formatDcDate(endDate, true) + "]");
  return solr_params;
}

export function GetSearchResults(solr_params, params, content) {
  const { setItems } = ItemsStore();
  const { setFacets } = FacetsStore();
  params = Object.fromEntries([...params]);

  solr_params.start = params.firstRow - 1
  solr_params.rows = params.numberOfRows
  solr_params.hl = "on"
  solr_params.hl_fl = "dcTitle,dcDescription,content"
  // dcSubject
  if (params.dcSubject) {
    solr_params.fq.push("dcSubject:(" + encodeURIComponent('"' + params.dcSubject + '"') + ")");
  }
  // dcType
  if (params.dcTypeFilter) {
    solr_params.fq.push("dcType:" + params.dcTypeFilter);
  } else {
    solr_params.fq.push("!dcType:collection");
  }
  // id
  if (params.id) {
    solr_params.fq.push("id:" + params.id);
  }
  let url = archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
  console.info(url);
  axios.get(url).then((res) => {
    const result = res.data;
    setItems(prevItems => [...prevItems, ...result.response.docs]);
  });

  solr_params.fq = []

  if (params.dcCollectionFilter) {
    // https://stackoverflow.com/questions/10023133/solr-wildcard-query-with-whitespace
    let dcIsPartOf = params.dcCollectionFilter.split(" ").join("%20");
    solr_params.fq.push('dcIsPartOf:"' + dcIsPartOf + '"');
  }

  solr_params.rows = 1;
  solr_params.facet = "on";
  solr_params.facet_field = "dcType";
  url = archieExports["docs_endpoint"] + "?" + solr_params.toQuerySring();
  console.info(url);
  axios.get(url).then((res) => {
    const result = res.data;
    setFacets({
      total: result.response.numFound,
      counters: result.facet_counts.facet_fields,
      highlights: result.highlighting,
    });
  });
}
