import React, { useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Item } from "./Item";
import { PhotoGallery } from "./PhotoGallery";
import { ItemsStore, ViewStore, ScrollStore, FacetsStore } from "./Store";
import { Viewer } from "../components/Viewer";
import { useEffect } from "react";

import { GetSearchResults, BuildSolrSearchQuery } from "./SearchEngine";
import InfiniteScroll from "react-infinite-scroll-component";

export const ItemsCollection = () => {
  const { items } = ItemsStore();
  const { view, setView } = ViewStore();
  const { facets } = FacetsStore();

  const [params, setSearchParams] = useSearchParams();
  const { scrollPosition, setScrollPosition } = ScrollStore();
  const [localScroll, setLocalScroll] = useState(0);

  useEffect(() => {
    if (scrollPosition) {
      window.scrollTo(0, localScroll);
      setScrollPosition(false);
    }
  }, [scrollPosition, localScroll, setScrollPosition]);

  useEffect(() => {
    const onScroll = () => {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setLocalScroll(window.pageYOffset);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    if (items.length > 0 && params.has("id")) {
      const item = items.find((item) => item.id === params.get("id"));
      setView(item);
    }
  }, [items, setView, params]);

  const loadMoreItems = () => {
    if (facets.total - parseInt(params.get("firstRow")) >= 50) {
      params.set("firstRow", parseInt(params.get("firstRow")) + 50);

      setSearchParams(createSearchParams(params));
      let searchQuery = "";

      searchQuery = BuildSolrSearchQuery(params);

      GetSearchResults(searchQuery, params, false);
    }
  };

  return (
    <>
      {params.get("dcTypeFilter") !== "image" ? (
        <Container>
          {view ? (
            <Viewer />
          ) : items !== undefined ? (
            <ItemsWrapper>
              <InfiniteScroll
                dataLength={items.length}
                next={loadMoreItems}
                hasMore={true}
                loader={<h4>Loading...</h4>}
              >
                <Items>
                  {items.map((item) => {
                    return <Item data={item} key={item.id} />;
                  })}
                </Items>
              </InfiniteScroll>
            </ItemsWrapper>
          ) : null}
        </Container>
      ) : items !== undefined && items.length > 0 ? (
        <PhotoGallery />
      ) : null}
    </>
  );
};

const ItemsWrapper = styled.div`
  width: 100%;
  margin: 16px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
   .infinite-scroll-component__outerdiv {
width: 100%;
}
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #c6e5f7;
  padding-bottom: 118px;
`;

const Items = styled.div`
  width: 100%;

  display: grid;
  direction: rtl;
  grid-template-columns: 1fr;
  justify-items: center;

  @media (min-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(347px, 1fr));
  }

  grid-gap: 24px;
`;
